<template>
  <div style="padding:30px 0px;text-align:center">
    <img src="" alt="" id="imgCopy" style="height: 200px; width: 200px;">
    <p style="text-align:center;padding:10px;">注：扫描或长按二维码进入公众号</p>
  </div>
</template>
<script>
import { accessToken } from '../utils/apis/apis.js'
export default {
  name: 'wxGetInfo',
  data() {
    return {
      tipMessage: '信息加载中，请稍候...'
    }
  },
  mounted() {
    var memberId = this.getQueryString('memberId')
    this.$reqGet(accessToken.getCurrentQecode, {
      memberId: memberId
    }).then(res => {
      document
          .getElementById("imgCopy")
          .setAttribute("src", "data:image/png;base64," + res.data.data);
    })
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    }
  }
}
</script>